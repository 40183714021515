import React, {SyntheticEvent, useContext, useEffect, useState} from 'react';
import {apiURL} from '../utils/apiURL';
import {UserContext} from "../context/user.context";
import {Link, useNavigate} from 'react-router-dom';
import Logo from "../assets/images/login-trip.jpg";
import {Button} from "./Button";

export const Login = () => {
    const [form, setForm] = useState({
        email: '',
        password: '',
    });
    const [summary, setSummary] = useState('');
    const [error, setError] = useState(false);
    const [showPass, setShowPass] = useState(false)

    const {setUserLog} = useContext(UserContext);
    const navigate = useNavigate()
    useEffect(() => {

    }, []);
    
    const loginUser = async (e: SyntheticEvent) => {
        e.preventDefault();
        try {
            if (!form.email || !form.password) {
                throw new Error('Fields are required');
            }

            const res = await fetch(`${apiURL}api/user/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...form,
                }),
            });

            if (!res.ok) {
                throw new Error('Invalid email or password');
            }

            const data = await res.json();

            if (data[0].token) {
                setError(false);
                localStorage.setItem('logged-user', JSON.stringify(data[0]));
                setUserLog(true)
                navigate('/')
            }
        } catch (e) {
            const error = e instanceof Error ? e.message : 'Sorry try again later';
            const errorMessage = e instanceof TypeError ? '' : error;
            setSummary(errorMessage);
            setError(true);
        }
    };

    const updateForm = (key: string, value: any) => {
        setForm(form => ({
            ...form,
            [key]: value,
        }));
    };

    const showPassword = (e: SyntheticEvent) => {
        e.preventDefault()
        !showPass ? setShowPass(true) : setShowPass(false)
    };

    return (
        <div className="bg-gray-50 min-h-[80vh] flex items-center justify-center">
            <div className="bg-gray-100 flex rounded-2xl shadow-lg max-w-3xl p-5 items-center">
                <div className="md:w-1/2 px-8 md:px-16">
                    <h2 className="font-bold text-2xl text-[#002D74]">Login</h2>
                    <p className="text-xs mt-4 text-[#002D74]">If you are already a member, easily log in</p>
                    <p className="text-xs text-[red]">{summary}</p>
                    <form onSubmit={loginUser} className="flex flex-col gap-4">
                        <input
                            className={!form.email && error ? "w-full rounded-md border border-[red] bg-red placeholder-red-300 p-2 mt-8 rounded-xl border" : "p-2 mt-8 rounded-xl border"}
                            type="email" name="email" placeholder="Email" value={form.email}
                            onChange={e => updateForm('email', e.target.value)}/>
                        <div className="relative">
                            <input
                                className={!form.email && error ? "rounded-md border border-[red] bg-red placeholder-red-300 p-2 p-2 rounded-xl border w-full" : "p-2 rounded-xl border w-full"}
                                type={!showPass ? "password" : "text"} name="password"
                                placeholder="Password" value={form.password}
                                onChange={e => updateForm('password', e.target.value)}/>
                            <button onClick={showPassword}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="gray"
                                     className="bi bi-eye absolute top-1/2 right-3 -translate-y-1/2"
                                     viewBox="0 0 16 16">
                                    <path
                                        d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                                    <path
                                        d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                                </svg>
                            </button>
                        </div>
                       <Button name="Login"/>
                    </form>
                    <div className="my-6 grid grid-cols-3 items-center text-gray-400">
                        <hr className="border-gray-400"/>
                        <p className="text-center text-sm">OR</p>
                        <hr className="border-gray-400"/>
                    </div>

                    <div className="flex flex-col gap-4">
                        <Link className="flex flex-col gap-4" to="/registration">
                            <Button name="Register"/>
                        </Link>
                    </div>

                </div>

                <div className="md:block hidden h-[80%] w-1/2">
                    <img className="rounded-2xl"
                         src={Logo}/>
                </div>
            </div>
        </div>
    );
};

